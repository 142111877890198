var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"width":600,"visible":_vm.visible,"title":_vm.type ? _vm.$t('student.' + _vm.type) : '',"maskClosable":false,"okButtonProps":{ props: { loading: _vm.saveLoading } },"centered":"","destroyOnClose":""},on:{"cancel":_vm.cancel,"ok":_vm.saveParent}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.parentEditor,"autocomplete":"nope"}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('student.name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              {
                max: 50,
                message: _vm.$t('setup.textLimit50'),
              },
              {
                validator: _vm.validateName,
              } ],
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              {\n                max: 50,\n                message: $t('setup.textLimit50'),\n              },\n              {\n                validator: validateName,\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":_vm.$t('student.enterName'),"disabled":_vm.isExisted}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('student.englishName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'enName',
          {
            rules: [
              {
                max: 50,
                message: _vm.$t('setup.textLimit50'),
              },
              { validator: _vm.validateName } ],
          } ]),expression:"[\n          'enName',\n          {\n            rules: [\n              {\n                max: 50,\n                message: $t('setup.textLimit50'),\n              },\n              { validator: validateName },\n            ],\n          },\n        ]"}],attrs:{"placeholder":_vm.$t('student.enterenName'),"disabled":_vm.isExisted}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('student.mobile')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mobile',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('student.enterMobile'),
              },
              {
                validator: _vm.validateOtherParent,
              } ],
          } ]),expression:"[\n          'mobile',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('student.enterMobile'),\n              },\n              {\n                validator: validateOtherParent,\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('student.enterMobile')},on:{"blur":_vm.validatePhone}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('student.email')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('student.enterEmail'),
                type: 'email',
                message: _vm.$t('student.enterCorrectEmail'),
              } ],
          } ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('student.enterEmail'),\n                type: 'email',\n                message: $t('student.enterCorrectEmail'),\n              },\n            ],\n          },\n        ]"}],attrs:{"disabled":_vm.isExisted,"placeholder":_vm.$t('student.enterEmail')}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('student.relation')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'relationship',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('student.selectRelation'),
              },
              {
                validator: _vm.validateRelation,
              } ],
          } ]),expression:"[\n          'relationship',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('student.selectRelation'),\n              },\n              {\n                validator: validateRelation,\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":_vm.$t('student.selectRelation')}},_vm._l((_vm.roleList),function(item){return _c('a-select-option',{key:item},[_vm._v(_vm._s(_vm.$t('relation.' + item)))])}),1)],1),(_vm.isMajor)?[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('student.nationality')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'countryId',
            {
              rules: [],
            } ]),expression:"[\n            'countryId',\n            {\n              rules: [],\n            },\n          ]"}],attrs:{"placeholder":_vm.$t('student.selectCountry')}},_vm._l((_vm.countryList),function(item){return _c('a-select-option',{key:item.key},[_vm._v(_vm._s(_vm.locale === 'zh' ? item.value : item.enValue))])}),1)],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('student.position')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'position',
            {
              rules: [
                {
                  max: 50,
                  message: _vm.$t('setup.textLimit50'),
                } ],
            } ]),expression:"[\n            'position',\n            {\n              rules: [\n                {\n                  max: 50,\n                  message: $t('setup.textLimit50'),\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":_vm.$t('student.enterWorkPosition')}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('student.employer')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'workUnit',
            {
              rules: [
                {
                  max: 50,
                  message: _vm.$t('setup.textLimit50'),
                } ],
            } ]),expression:"[\n            'workUnit',\n            {\n              rules: [\n                {\n                  max: 50,\n                  message: $t('setup.textLimit50'),\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":_vm.$t('student.enterWorkUnit')}})],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }