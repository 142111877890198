import { render, staticRenderFns } from "./Instruction.vue?vue&type=template&id=0bd3a827&scoped=true&"
import script from "./Instruction.vue?vue&type=script&lang=ts&"
export * from "./Instruction.vue?vue&type=script&lang=ts&"
import style0 from "./Instruction.vue?vue&type=style&index=0&id=0bd3a827&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd3a827",
  null
  
)

export default component.exports